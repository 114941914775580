export var FilterComparator;
(function (FilterComparator) {
    FilterComparator["Includes"] = "includes";
    FilterComparator["StartsWith"] = "starts_with";
    FilterComparator["EndsWith"] = "ends_with";
    FilterComparator["IsEqual"] = "is_equal";
    FilterComparator["IsDifferent"] = "is_different";
    FilterComparator["IsLower"] = "is_lower";
    FilterComparator["IsHigher"] = "is_higher";
    FilterComparator["IsBefore"] = "is_before";
    FilterComparator["IsAfter"] = "is_after";
    FilterComparator["IsIn"] = "is_in";
})(FilterComparator || (FilterComparator = {}));
export const FilterCategories = {
    Numeric: [
        FilterComparator.IsEqual,
        FilterComparator.IsDifferent,
        FilterComparator.IsLower,
        FilterComparator.IsHigher,
    ],
    String: [
        FilterComparator.Includes,
        FilterComparator.StartsWith,
        FilterComparator.EndsWith,
        FilterComparator.IsEqual,
        FilterComparator.IsDifferent,
    ],
    Date: [
        FilterComparator.IsEqual,
        FilterComparator.IsDifferent,
        FilterComparator.IsBefore,
        FilterComparator.IsAfter,
    ],
};
export function applyFilters(items = [], filters = []) {
    return items.filter((item) => {
        let keep = true;
        filters.forEach((filter) => {
            const value = item[filter.key];
            const comp = filter.value;
            switch (filter.comparator) {
                case FilterComparator.Includes:
                    keep = keep && `${value}`.toLowerCase().includes(comp.toLowerCase());
                    break;
                case FilterComparator.StartsWith:
                    keep =
                        keep && `${value}`.toLowerCase().startsWith(comp.toLowerCase());
                    break;
                case FilterComparator.EndsWith:
                    keep = keep && `${value}`.toLowerCase().endsWith(comp.toLowerCase());
                    break;
                case FilterComparator.IsEqual:
                    keep = keep && `${value}`.toLowerCase() === comp.toLowerCase();
                    break;
                case FilterComparator.IsDifferent:
                    keep = keep && `${value}`.toLowerCase() !== comp.toLowerCase();
                    break;
                case FilterComparator.IsLower:
                    keep = keep && Number(value) < Number(comp);
                    break;
                case FilterComparator.IsHigher:
                    keep = keep && Number(value) > Number(comp);
                    break;
                case FilterComparator.IsBefore:
                    keep = keep && new Date(`${value}`) < new Date(comp);
                    break;
                case FilterComparator.IsAfter:
                    keep = keep && new Date(`${value}`) > new Date(comp);
                    break;
                case FilterComparator.IsIn:
                    keep =
                        keep && !!filter.value.find((i) => i === `${value}`);
                    break;
                default:
                    break;
            }
        });
        return keep;
    });
}
