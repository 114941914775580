import {
  BareMetalCloud,
  HostedPrivateCloud,
  Network,
  PublicCloud,
  SecurityIdentityOperation,
  Sunrise,
  Telecom,
  Webcloud,
  Helpe,
  Leaf,
  LiveChat,
  NetworkStatus,
  Store,
  Email,
  Globe,
  LockClose,
  Monitor,
  Networkv2,
  Phone,
  ServerV2,
  ShieldCheck,
  ShoppingCartPlus,
  Star,
} from '../assets-icon-svg/icons';

const containerIcons = {
  BareMetalCloud,
  HostedPrivateCloud,
  Network,
  PublicCloud,
  SecurityIdentityOperation,
  Sunrise,
  Telecom,
  Webcloud,
  Helpe,
  Leaf,
  LiveChat,
  NetworkStatus,
  Store,
  Email,
  Globe,
  LockClose,
  Monitor,
  Networkv2,
  Phone,
  ServerV2,
  ShieldCheck,
  ShoppingCartPlus,
  Star,
};

export default containerIcons;
