export const MAX_NOTIFICATIONS = 10;

export const NOTIFICATION_STATUS_ENUM = {
  ACKNOWLEDGED: 'acknowledged',
  ACTIVE: 'delivered',
  COMPLETED: 'completed',
  UNKNOWN: 'unknown',
};

export const ACKNOWLEDGED_STATUS = [
  NOTIFICATION_STATUS_ENUM.ACKNOWLEDGED,
  NOTIFICATION_STATUS_ENUM.COMPLETED,
  NOTIFICATION_STATUS_ENUM.UNKNOWN,
];

export default {
  ACKNOWLEDGED_STATUS,
  MAX_NOTIFICATIONS,
  NOTIFICATION_STATUS_ENUM,
};
